<template>
  <icon-demo/>
</template>

<script>
import IconDemo from '@/components/IconDemo'
export default {
  components: { IconDemo }
}
</script>

<style lang="scss" scoped>

</style>
